import React from "react"
import {graphql} from "gatsby";
import Sticky from "react-sticky-el"
import SEO from "../components/SEO/index"
import Contact from "../components/Sections/Contact"
import AboutSection from "../components/Sections/AboutSection"
import NavBar from "../components/navbar"
import localize from "../components/localize";

const AboutPage = (props) => {
    const contact = props.data.contacts.edges[0].node;
    const address = props.data.address.edges;
  return (
    <>
      <SEO title="Dryprod" description="О компании" />
      <Sticky className="sticky-selector" topOffset={150}>
        <div className="is-shadow">
          <NavBar data={props.data.menu} />
        </div>
      </Sticky>
      <AboutSection
          title={props.data.about.edges[0].node.section} desc={props.data.about.edges[0].node.section_desc} />
      <Contact contact={contact} address={address} />
    </>
  )
}

export default localize(AboutPage);

export const AboutPageQuery = graphql`
  query {
      menu: allSanityMenu {
      edges {
        node {
           name {
             _type
             en
             ru
           }
         }
       }
     }
     address: allSanityAddress {
        edges {
          node {
            address {
              en
              ru
              _type
            }
          }
        }
      }
     about: allSanityAbout {
        edges {
          node {
            hero {
              _type
              en
              ru
            }
            section {
              _type
              en
              ru
            }
            section_desc {
              _type
              en
              ru
            }
          }
        }
      }
      contacts: allSanityContact {
        edges {
          node {
            phone {
              en
              ru
            }
            copyright {
              en
              ru
              _type
            }
            phone_numbers {
              _type
              en
              ru
            }
            social {
              _type
              en
              ru
            }
            textarea {
              _type
              en
              ru
            }
            our_contacts {
              _type
              en
              ru
            }
            name {
              _type
              en
              ru
            }
            address {
              _type
              en
              ru
            }
            button {
              _type
              en
              ru
            }
            copyright {
              _type
              en
              ru
            }
            email {
              _type
              en
              ru
            }
            email_title {
              _type
              en
              ru
            }
            leave_request {
              _type
              en
              ru
            }
          }
        }
      }  
  }
`
