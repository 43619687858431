import React from "react"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"

const AboutSection = ({title, desc}) => {
  return (
    <Section white>
      <Columns centered>
        <Column is11 offset1>
          <SectionTitle bottom>
            {title}
          </SectionTitle>

          <p className="mb-2 is-size-4 is-size-7-mobile">
            {desc}
          </p>
        </Column>
      </Columns>
    </Section>
  )
}

export default AboutSection
